import(/* webpackMode: "eager" */ "/vercel/path0/packages/shared-styles/src/clock/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shared-styles/src/divisibles/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shared-styles/src/hamburger/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shared-styles/src/layout/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shared-styles/src/nav/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shared-styles/src/section/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shared-styles/src/sections/education/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shared-styles/src/sections/experience/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shared-styles/src/sections/skills/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shared-styles/src/styled-go-back/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shared-styles/src/three-dots/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shared-styles/src/tooltip/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shared-styles/src/view-source/styles.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["HoverLink"] */ "/vercel/path0/portfolios/next-portfolio/src/components/card-hover-effect/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/portfolios/next-portfolio/src/components/divisor/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portfolios/next-portfolio/src/components/progress-bar/next.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/portfolios/next-portfolio/src/components/sections/download/index.tsx");
